import React from "react";

const PopupAd = ({ isVisible, onClose, imageUrl, adLink }) => {
    if (!isVisible) return null; // Don't render if ad is not visible
  
    return (
      <div className="popup-overlay">
        <div className="popup-content">
          <button className="close-btn" onClick={onClose}>
            ✖
          </button>
  
          {/* Clickable Ad Image */}
          <a href={adLink} target="_blank" rel="noopener noreferrer">
            <img src={imageUrl} alt="Advertisement" className="ad-image" />
          </a>
        </div>
      </div>
    );
  };

export default PopupAd;
